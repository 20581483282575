
@import '~@yeastar/pc-web-ui/src/style/variables.less';

.RadioBtn{
    width: 60px;
    height: 32px;
    background: @gray-1;
    border-radius: 4px;

    display: flex;
    padding: 2px;

    .RadioBtn-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        color: @gray-7;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.01), 0 3px 6px 3px rgba(0,0,0,0.01), 0 2px 6px 0 rgba(0,0,0,0.03);
        border-radius: 2px;
        cursor: pointer;
    }
    .RadioBtn-btn:hover{

        color: @gray-9;
    }
    .RadioBtn-btn.RadioBtn-btn-active{
        background:#fff;
        color: @gray-9;
    }

}
