.OfficeMap {
    position: relative;
    height: calc(100% + 48px);
    margin: -24px;
    user-select: none;
    .search {
        padding: 0 24px;
        position: relative;
        z-index: 11;
        padding-top: 16px;
    }
    .icons {
        position: fixed;
        bottom: 84px;
        right: 16px;
        z-index: 99;
        .item {
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02),
                0 2px 6px 0 rgba(0, 0, 0, 0.04);
            cursor: pointer;
        }
        .map_dropdown_container {
            display: flex;
            align-items: flex-end;
            margin-top: -4px;
        }
    }

    .so-plan-banner-wrapper {
        position: relative;
        padding: 24px 24px 0;
        z-index: 11;

        .so-plan-banner {
            margin-bottom: 0;
        }
    }
}
.wp-filter-bar-item {
    margin-left: 0 !important;
    margin-right: 24px;
    &:last-child {
        margin-right: 0;
    }
    .separate {
        margin: 0 8px;
    }
    .wp-filter-bar-more-btn {
        background-color: #fff;
    }
    .time-select {
        flex: 1;
    }
    .filterExtra {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .timezone-display {
        position: absolute;
        white-space: nowrap;
        bottom: -2px;
        transform: translateY(100%);
        line-height: 16px;
        color:rgba(0, 0, 0, 0.6);
    }
    .filterTime {
        display: flex;
        align-items: center;
        position: relative;
        .timezone-display {
            left: 0;
            line-height: 16px;
        }
    }
    .filter-location {
        position: relative;
        .timezone-display {
            left: 0;
            line-height: 16px;
        }
    }
    .ant-select-show-arrow {
        .ant-select-arrow {
            z-index: 1;
            height: 100%;
            width: 32px;
            margin-top: 0;
            top: -1px;
            right: 3px;
            .anticon {
                width: 100%;
                height: 100%;
                line-height: 32px;
                color: rgba(0, 0, 0, 0.38);
                >svg {
                    vertical-align: middle;
                }
            }
        }
    }
}
