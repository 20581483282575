@import '~@yeastar/pc-web-ui/src/style/variables.less';

@picker-prefix-cls: ~'ant-picker';

// 日期选择器
.override-@{picker-prefix-cls} {
    width: 210px;
    height: 0;
    transform: translateY(-22px);
    opacity: 0;
}

// divider
.override-ant-divider {
    height: 100vh;
    margin: 0 24px;
    border-left-color: #f2f2f2;
}

.override-@{picker-prefix-cls}-dropdown {
    z-index: 100;
    color: @gray-base;

    .@{picker-prefix-cls}-panel-container {
        box-shadow: none;
    }

    .@{picker-prefix-cls}-header {
        border: 1px solid @separator-color-base;
    }

    .@{picker-prefix-cls}-panel {
        border: none;
    }

    .@{picker-prefix-cls}-cell-inner {
        &-dot {
            position: relative;

            &::after {
                position: absolute;
                bottom: -6px;
                left: 50%;
                width: 4px;
                height: 4px;
                background-color: @blue-base;
                border-radius: 50%;
                transform: translateX(-50%);
                content: ' ';
            }
        }
    }
}

// 会议日程列表
.conference-schdule {
    min-width: 900px;
    height: calc(100vh - 100px);

    &-picker-wrapper {
        width: 280px;
    }

    &-list-wrapper {
        position: relative;
        width: 542px;
        max-height: calc(100vh - 100px);

        .loading {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: calc(100vh - 100px);

            .ant-spin-nested-loading {
                width: auto;
                height: auto;
            }
        }
    }

    &-list {
        position: relative;
        padding-bottom: @padding-lg;

        .ant-typography {
            color: @gray-7;
        }

        h5.ant-typography {
            margin-bottom: 8px;
            font-size: @font-size-base;
        }

        &:first-child {
            .conference-schdule-list-title {
                color: @blue-base !important;
            }
        }

        &-title {
            position: relative;
            z-index: 9;
            width: 100%;
            height: 24px;
            margin: 0; //不应该有margin，margin不会撑开父容器
            padding-left: @padding-md;
            color: inherit !important;
            line-height: 24px;
            background: #fff;
        }

        &-item {
            height: 64px;
            padding: 10px @padding-md;
            font-size: @font-size-xs;
            border-bottom: 1px solid @separator-color-base;
            transition: background-color 0.3s;

            &:hover {
                background-color: @background-color-hover;
                cursor: pointer;
            }

            &-subject {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: @font-size-base;
            }

            &-disabled {
                color: @gray-5;
                .ant-typography {
                    color: @gray-5 !important;
                }
            }
            &-meetingRoomName.ant-typography {
                color: @gray-7;
                font-size: @font-size-base;
                padding-left: 8px;
            }

            &-time {
                width: 72px;
                line-height: 22px;
            }

            &-time-placeholder {
                width: 72px;
                visibility: hidden;
            }
        }
    }
}

.list-view-scrollview {
    .sticky-container {
        margin-bottom: @padding-lg;
        background: #fff;
        &:first-child {
            .sticky-row-item {
                color: @blue-5;
            }
        }
        &-active {
            .sticky-row-item {
                color: @blue-5;
            }
        }
    }
    .sticky-row {
        &-item {
            position: sticky !important;
            top: 0;
            z-index: 9;
            width: 100%;
            height: 24px;
            margin-bottom: @padding-xs;
            padding-left: @padding-md;
            font-weight: 600;
            font-size: @font-size-base;
            line-height: 24px;
            background: #fff;
            transform: translateZ(0);
        }
    }
}
