@import '~@yeastar/pc-web-ui/src/style/variables.less';

.meeting-info-popover {
    .meeting-name {
        width: 100%;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        text-align: center;
        text-overflow: ellipsis;
        display: flex;
        justify-content: space-between;

        .more-info-icon {
            color: @gray-6;
            cursor: pointer;
        }
        .meeting-name-title {
            display: inline-block;
            max-width: 320px;
            overflow: hidden;
            color: rgba(0, 0, 0, 0.87);
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .so-plan-lock {
            display: inline-block;
            max-width: 320px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .meeting-name-title {
                display: inline;
                color: inherit;
            }
        }
    }
    .meeting-type {
        margin-top: 8px;
        font-size: 14px;
    }
    .meeting-number {
        width: 100%;
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        filter: blur(10);
        margin-top: 4px;
        //.ant-tag {
        //    margin-right: 4px;
        //    margin-bottom: 4px;
        //    color: rgba(0, 0, 0, 0.6);
        //}
        .ant-space {
            display: block;
        }
        .meeting-inline-box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            //max-width: 320px;
            .meeting-address-show {
                flex: 1;
                display: inline-block;
                //max-width: 290px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .meeting-number.show-left {
        margin-bottom: 2px;
        text-align: left;
    }
    .meeting-name.show-left {
        position: relative;
        height: 24px;
        margin-bottom: 7px;
        .Icon-content {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .meeting-device-state {
        margin-top: 8px;
    }
}
