.date-choose {
    display: flex;
    .ant-btn {
        min-width: 64px;
        margin-right: 8px;
        padding: 4px 7px;
    }
    .date-operator {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        border: 1px solid rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        .anticon {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .date-operator-left {
            padding: 0 4px 0 8px;
            &:active {
                opacity: 0.7;
            }
        }
        .date-operator-right {
            padding: 0 8px 0 4px;
            &:active {
                opacity: 0.7;
            }
        }
        .ant-divider {
            margin: 0 3px 0 2px;
        }
    }
}
