@primary-color: #006EE1;

.wp-avatar {
    &__group {
        display: inline-flex;
        flex-direction: row-reverse;
    }

    &__item {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        margin: 0;
        margin-left: -6px;
        padding: 0;
        overflow: hidden;
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        text-align: center;
        vertical-align: middle;
        background: @primary-color;
        border: 1px solid #fff;
        border-radius: 50%;

        &.show-avatar-num {
            color: rgba(0, 0, 0, 0.87);
            font-size: 10px;
            background: #E6F6FF,
        }
    }

    &__string {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(1) translateX(-50%) translateY(-50%);
        transform-origin: 0 center;
    }

    &__image {
        background: 0 0;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-style: none;
        }
    }
}
