.ww-open-data-wrapper {
    min-height: 34px;
    padding: 5px 8px;
    color: rgba(0, 0, 0, 0.25);
    background-color: #F7F7F7;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    cursor: not-allowed;
    opacity: 1;
}
