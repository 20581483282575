.time-zone-header {
    position: relative;
    padding-right: 16px;

    &__utc {
        &-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;
        }

        &-name {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            min-height: 40px;
            overflow: hidden;
            color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            white-space: nowrap;
            cursor: pointer;
        }
    }
}
