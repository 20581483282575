.schedule-list-item {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    cursor: pointer;

    .list-item-type {
        display: flex;
        align-items: center;
        margin: 8px;

        &__name {
            margin-left: 4px;
            color: rgba(0, 0, 0, 0.87);
            font-size: 12px;
            line-height: 16px;
        }

        .blue {
            color: #006ee1;
        }

        .green {
            color: #20c161;
        }

        .yellow {
            color: #fe8c00;
        }
    }

    .list-item__wrapper {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-bottom: 4px;
        padding: 20px 16px 16px;
        border: 1px solid rgba(0, 0, 0, 0.13);
        border-radius: 8px;

        .item-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .item-title {
            padding-right: 8px;
            overflow: hidden;
            color: rgba(0, 0, 0, 0.87);
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.is-past {
                color: rgba(0, 0, 0, 0.38)
            }
        }

        .item-row {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.6);

            &.margin-bottom8 {
                margin-bottom: 8px;
            }
        }

        .list-item__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
        }

        .list-item__text {
            overflow: hidden;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            &.line-height20 {
                line-height: 20px;
            }
        }

        .item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
