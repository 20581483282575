@import '~@yeastar/pc-web-ui/src/style/variables.less';

.my-info {
    .tip {
        color: @gray-5;
        font-size: @font-size-xs;
    }

    h5.paragraph {
        padding: 8px 0;
    }

    .paragraph {
        border-bottom: 1px solid @separator-color-base;
    }
}
