@import '~@yeastar/pc-web-ui/src/style/variables.less';

.notify-setting {
    margin-bottom: 32px;
    .tip {
        color: @gray-5;
        font-size: @font-size-xs;
    }

    h5.paragraph {
        padding-top: 0;
        padding-bottom: 8px;
    }

    div.ant-typography {
        margin-bottom: 0;
        padding: 5px 0;
    }

    .paragraph {
        border-bottom: 1px solid @separator-color-base;
    }
}
