@primary-color: #006EE1;

.slide-icon(@rotate: 30deg ) {
    &::after {
        display: inline-block;
        width: 16px;
        height: 4px;
        background-color: #bbb;
        border-radius: 16px;
        transform: rotate(@rotate) translateX(-3px);
        content: '';
    }
    &::before {
        position: relative;
        z-index: -1;
        display: inline-block;
        width: 16px;
        height: 4px;
        background-color: #bbb;
        border-radius: 16px;
        transform: rotate(-@rotate) translateX(3px);
        content: '';
    }
}

.schedule-date-picker {
    &__wrapper {
        position: relative;
        z-index: 9;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        text-align: center;
        background-image: linear-gradient(0deg, #F4FBFF 0%, #E6F6FF 100%);
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        font-size: 14px;
        line-height: 56px;
        padding: 0 16px;
    }

    &__title {
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.87);
    }

    &__arrow {
        display: flex;
        align-items: center;
        width: 16px;
        color: rgba(0, 0, 0, 0.6);
    }

    &__footer {
        padding: 8px 2px;
    }

    &__slide {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-disabled {
            display: none;
        }
        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            overflow: hidden;
            transform: translate3d(-50%, -50%, 0);
            &--week {
                .slide-icon(-30deg);
            }
            &--month {
                .slide-icon();
            }
        }
    }

    &__weekdays {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
    }

    &__weekday {
        z-index: 1;
        flex: 1 0 14.2%;
        &-string {
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
    }

    &__scrollable-wrapper {
        position: relative;
        height: 70px;
        overflow: hidden;
        transition: height 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__scrollable-list {
        transition: transform 0.3s;
    }

    &__days {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &__item {
        position: relative;
        flex: 1 0 14.2%;
        padding: 8px 0;
    }
    &__day {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
        &--selected-future {
            color: #fff !important;
            background-color: @primary-color !important;
            border-radius: 50%;
        }
        &--selected-past {
            color: rgba(0, 0, 0, 0.87) !important;
            background-color: rgba(0, 0, 0, 0.13) !important;
            border-radius: 50%;
        }
        &--today {
            color: @primary-color;
        }
        &--marked {
            &-dot {
                position: relative;
                &::after {
                    position: absolute;
                    bottom: -6px;
                    left: 50%;
                    width: 4px;
                    height: 4px;
                    background-color: @primary-color;
                    border-radius: 50%;
                    transform: translate3d(-50%, 0, 0);
                    content: '';
                }
            }
            &-underline {
                position: relative;
                &::after {
                    position: absolute;
                    bottom: -6px;
                    left: 50%;
                    width: 14px;
                    height: 2px;
                    background-color: @primary-color;
                    transform: translate3d(-50%, 0, 0);
                    content: '';
                }
            }
        }
        &--nextmonth,
        &--prevmonth {
            color: rgba(0, 0, 0, 0.38);
        }
        &--disabled {
            color: rgba(0, 0, 0, 0.38) !important;
            background-color: rgba(0, 0, 0, 0.13) !important;
            border-radius: 50%;
        }
    }

    &__bar-wrapper {
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
        cursor: pointer;
    }

    &__horizontal-bar {
        width: 40px;
        height: 6px;
        background: rgba(0, 0, 0, 0.07);
        border-radius: 3px;
        pointer-events: none;
    }

    &__dots {
        position: absolute;
        top: 38px;
        left: 0;
        width: 100%;
        height: 8px;
    }
}
