.MeetingDetailMore{
    display: flex;
    width: 100%;
    // flex-direction: row;
    // flex: 1;
    .MeetingDetailMore-left{
        width: 178px;
        padding: 8px;
        border-right: 1px solid rgba(0,0,0,0.05) ;

        .left-content{
            min-height: 200px;
            max-height: 600px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .MeetingDetailMore-left-item{
            //height: 32px;
            font-size: 12px;
            text-align: center;
            //line-height: 32px;
            border-radius: 4px;
            padding: 5px 12px;
            cursor: pointer;
            word-wrap: break-word;
        }
        .MeetingDetailMore-left-item:hover{
            background: rgba(0,0,0,0.04);
        }
        .MeetingDetailMore-left-item.active{
            color: #0054ba;
            background: #e6f6ff;
            // border: 1px solid #e6f6ff;
        }

    }
    .MeetingDetailMore-right{
        width: 370px;
    }
}
.loading-box{
    width: 100%;
    height: 200px;
}
