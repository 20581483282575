@import '~@yeastar/pc-web-ui/src/style/variables.less';
.desk-info-popover {
    .header-desk-name {
        font-size: 18px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .more-info-icon {
            color: @gray-6;
            cursor: pointer;
        }
        .header-desk-show-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 8px;
        }
    }
    .header-desk-sensor {
        margin-top: 8px;
    }
    .header-desk-building {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 4px;
        overflow: hidden;
        display: flex;
        align-items: center;
        line-height: 18px;
        .building-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.more-info-popover {
    width: 400px;
    .ant-popover-inner-content {
        padding: 24px;
    }
    .header-popover-content {
        max-width: 400px;
    }
}
