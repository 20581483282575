.EventContent {
    .event-msg {
        margin-top: 2px;
        font-size: 12px;
        line-height: normal;
    }
    .event-subject {
        margin-top: 0px !important;
        font-weight: bold;
    }
    .calendar-expired-tooltip {
        .ant-tooltip-arrow {
            visibility: hidden;
        }

        .ant-tooltip-inner {
            position: relative;
            bottom: -15px;
            min-height: 24px;
            padding: 2px 8px;
            border-radius: 4px;
            color: rgba(0, 0, 0, 0.87);
            background-color: #fff;
        }
    }
}
