@serch-width: 320px;
@serch-padding: 8px 16px;
.searchWrap {
    position: relative;
    .serchList {
        position: absolute;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02),
            0 2px 6px 0 rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        top: 40px;
        left: 0;
        background-color: #fff;
        width: @serch-width;
        max-height: 550px;
        // overflow: auto;
        .title {
            line-height: 16px;
            background: #f7f7f7;
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            text-align: left;
            padding: 4px 16px;
        }
        .userList {
            position: relative;
            border-radius: 4px;
        }
        .subList {
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02),
                0 2px 6px 0 rgba(0, 0, 0, 0.04);
            max-height: 550px;
            overflow: auto;
        }
        .itemList {
            background-color: #fff;
            .header {
                padding: 0 16px;
                line-height: 24px;
                display: flex;
                justify-content: space-between;
                padding-top: 8px;
                .more {
                    color: #006ee1;
                    cursor: pointer;
                }
            }
            .curItem {
                padding: 0 0 0 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                .avatar {
                    width: 32px;
                    height: 32px;
                    margin-right: 12px;
                    border-radius: 50%;
                }
                .avatarPic {
                    width: 32px;
                    height: 32px;
                    margin-right: 12px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 38px;
                    background-color: #e6f6ff;
                    color: rgb(2, 110, 225);
                }
                .leftContent {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 16px 8px 0;
                    border-bottom: 1px solid #eee;
                    height: 60px;
                    overflow-x: hidden;
                    .content {
                        overflow-x: hidden;
                        .name {
                            font-size: 14px;
                            color: rgba(0, 0, 0, 0.87);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .des {
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
                .arrow {
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }
        .ant-collapse,
        .ant-collapse-content {
            border-radius: 0;
            border: none;
        }
        .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
            left: auto;
            right: 16px;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.6);
            top: 0;
        }
        .ant-collapse-header {
            padding: @serch-padding;
            background: rgba(0, 0, 0, 0.04);
            font-weight: 600;
            color: rgba(0, 0, 0, 0.6);
            line-height: 16px;
            border: none;
        }
        .ant-collapse-content > .ant-collapse-content-box {
            padding: 0;
        }
    }
}
