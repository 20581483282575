//主题色确定后还需按实际变量修改
@border-color-default: #f2f2f2;
@border-color-select: #006ee1;
@font-color-default: rgba(0, 0, 0, 0.6);
@font-color-select: #0054ba;
@background-color-default: #e6f6ff;
@box-shadow-default: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
@background-color-select: @border-color-select;
@background-color-new-time: #f63b3b;
@background-color-time-column: #fff;
@background-color-event: #eeeeee;
@font-color-event-select: @background-color-time-column;
@background-color-select-not: #858585;

@font-color-select-approval-pending: #fe8c00;
@background-color-default-approval-pending: #fff8e6;
@background-color-default-approval-pending: #fff8e6;

.week-view-calendar {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 0 0 24px;

    //主要是复写样式
    :global {
        .ys-calendar-container {
            flex: 1;
            width: 100%;
            height: 100%;
            //控制事件区域有最大值
            // max-width: 300px;
            .ys-calendar {
                //控制事件区域有最小值
                .rbc-time-content {
                    // min-width: 138px;
                }
                .rbc-timeslot-group {
                    min-height: 60px;
                    border-bottom: 1px solid @border-color-default;
                }
                .rbc-day-slot.rbc-time-column {
                    // max-width: 300px;
                    background-color: @background-color-time-column;
                }
                // 去掉右内边距，避免时间刚好文本显示全，却因为多余的边距以...显示出来
                .rbc-day-slot .rbc-event-label {
                    padding-right: 0;
                }
                .rbc-time-header-content {
                    // max-width: 300px;
                    margin-right: 0;
                    margin-left: 0;
                    border-right: 1px solid @border-color-default;
                    border-left: none;
                }
                .ys-calendar-Header {
                    text-align: center;
                }
                .rbc-current-time-indicator {
                    background-color: @background-color-new-time;
                    width: calc(100% + 1px);
                }
                .rbc-time-view {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                    overflow-x: hidden;
                    border: none;
                }

                .rbc-events-container {
                    border: none !important;

                    .rbc-event,
                    .rbc-day-slot .rbc-background-event {
                        flex-flow: row wrap;

                        background: @background-color-event;
                        border: none;
                        filter: blur(10);
                        color: @font-color-default;
                        border-radius: 0;
                        .rbc-event-label {
                            width: 100%;
                            line-height: 18px;
                            font-size: 12px;
                        }
                        .rbc-event-label.rbc-event-label-short {
                            margin-top: -2px;
                            line-height: 12px;
                        }
                    }
                    .rbc-event > .rbc-event-label {
                        display: none !important;
                    }
                    .rbc-addons-dnd-resizable > .rbc-event-label {
                        display: none !important;
                    }
                }
                .rbc-slot-selection {
                    color: @font-color-select;
                    background: @background-color-default;
                    border: 1px solid @border-color-select !important;
                    box-shadow: @box-shadow-default;
                    filter: blur(10);
                }
                .rbc-event.rbc-selected,
                .rbc-day-slot .rbc-selected.rbc-background-event {
                    background: @background-color-event;
                }
                .eventWrapper {
                    .rbc-event {
                        top: 0 !important;
                        left: 0 !important;
                        width: 100% !important;
                        height: 100% !important;
                    }

                    .rbc-event.rbc-selected.ant-popover-open {
                        color: @font-color-event-select;
                        background: @background-color-select;
                    }
                    .ant-popover-open {
                        .rbc-event.rbc-selected {
                            color: @font-color-event-select;
                            background: @background-color-select;
                        }
                    }
                }
                .eventWrapper.not-own {
                    .rbc-event.rbc-selected.ant-popover-open {
                        color: @font-color-event-select;
                        background: @background-color-select-not;
                    }
                }
                .eventWrapper.disable {
                    .rbc-event,
                    .rbc-day-slot .rbc-background-event {
                        background: rgba(0, 0, 0, 0.04);

                        // color: @font-color-event-select;
                    }
                    div {
                        background: rgba(0, 0, 0, 0.04);
                    }
                }
                .eventWrapper.disable.roomDisable {
                    > div {
                        // display: flex;
                        // align-items: center;
                        // justify-content: center;
                        color: @font-color-default;
                        background: @background-color-event;
                        // 点击会议室禁用事件时的evt样式
                        &.ant-popover-open {
                            color: #fff;
                            background: #006ee1;
                        }
                    }
                    .rbc-event,
                    .rbc-day-slot .rbc-background-event {
                        align-items: center;
                        justify-content: center;
                        color: @font-color-default;
                        background: @background-color-event;
                    }
                }
                .rbc-event.rbc-addons-dnd-drag-preview {
                    color: @font-color-select;
                    background: @background-color-default;
                    border: 1px solid @border-color-select;
                    box-shadow: @box-shadow-default;
                    z-index: 99;
                }

                .eventWrapper.new {
                    .rbc-event {
                        color: @font-color-select;
                        background: @background-color-default;
                        border: 1px solid @border-color-select;
                        box-shadow: @box-shadow-default;
                    }

                    .rbc-addons-dnd-resize-ns-anchor {
                        cursor: ns-resize;
                        z-index: 99;
                        .rbc-addons-dnd-resize-ew-icon,
                        .rbc-addons-dnd-resize-ns-icon {
                            display: block !important;
                            width: 100% !important;
                            height: 5px;
                            border-top: transparent;
                        }
                    }
                    .rbc-addons-dnd-resize-ns-anchor:first-child {
                        top: -3px;
                    }
                    .rbc-addons-dnd-resize-ns-anchor:last-child {
                        bottom: -3px;
                    }
                }

                .eventWrapper.myEvent {
                    .rbc-event {
                        color: @font-color-select;
                        background: @background-color-default;
                        border: 1px solid #fff;
                    }
                }

                .eventWrapper.approval-pending-event {
                    .rbc-event {
                        color: #fe8c00;
                        background: #fff8e6;
                        border: 1px solid #fff8e6;
                    }
                }
                .rbc-header {
                    width: 64px;
                    padding: 0;
                    border-bottom: none;
                }
                .rbc-time-header {
                    display: inline-flex;
                    position: sticky;
                    top: 0;
                    margin-right: 0 !important;
                    overflow: visible !important;
                    border-top: none;
                    border-right: none;
                    border-bottom: none;
                    z-index: 99;
                    .rbc-header {
                        background-color: #fff;
                        max-width: 100%;
                        overflow: visible !important;
                    }
                }
                .rbc-time-header > div {
                    border-top: 1px solid @border-color-default;
                    border-bottom: 1px solid @border-color-default;
                }
                .rbc-time-header.rbc-overflowing {
                    width: fit-content;
                    min-width: 100%;
                    border-top: 1px solid @border-color-default;
                    border-right: none;
                    border-bottom: 1px solid @border-color-default;
                    .rbc-label.rbc-time-header-gutter {
                        position: sticky;
                        left: 0;
                        margin-right: 0 !important;
                        margin-left: 0 !important;
                        z-index: 100;
                        background: #fff;
                        padding: 0;
                    }
                }
                .rbc-time-header-content > .rbc-row.rbc-row-resource {
                    border-bottom: none;
                }

                .rbc-time-view .rbc-allday-cell {
                    display: none !important;
                }
                .rbc-time-content {
                    display: inline-flex;
                    height: auto;
                    padding-bottom: 3px;
                    overflow: visible;
                    border-top: none;
                    width: fit-content;
                    min-width: 100%;
                }
                .rbc-day-slot .rbc-time-slot {
                    border-top: none;
                }
                .rbc-day-slot .rbc-events-container {
                    margin-right: 0;
                    left: 3px;
                    right: 3px;
                }
                .rbc-time-content > * + * > * {
                    border-left: none;
                    border-right: 1px solid @border-color-default;
                }
                .rbc-time-gutter,
                .rbc-time-header-gutter {
                    border-right: none;
                    margin-right: 0;
                    .rbc-timeslot-group {
                        border-bottom: none;
                        position: relative;
                        // 移动左侧时间位置
                        .rbc-time-slot {
                            width: 100%;
                            min-width: 56px;
                            text-align: right;
                            .rbc-label {
                                position: unset;
                                padding: 0 8px;
                                width: 100%;
                                display: block;
                                text-align: center;
                                margin-top: -10px;
                                color: rgba(0, 0, 0, 0.6);
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }
                }

                .rbc-time-gutter
                .rbc-timeslot-group:first-of-type
                .rbc-time-slot
                .rbc-label {
                    margin-top: 0;
                }

                .rbc-time-gutter {
                    position: sticky;
                    left: 0;
                    z-index: 10;
                    margin-right: -1px;
                    background-color: #fff;
                    border-right: 1px solid #f2f2f2;
                }

                .EventContent{
                    .event-msg{
                        margin-top: 2px;
                        font-size: 12px;
                        line-height: normal;
                    }
                    .event-subject{
                        margin-top: 0px !important;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 24px 12px 0;

        &-label {
            color: rgba(0, 0, 0, 0.87);
            font-size: 20px;
            font-weight: 700;
            text-align: left;
        }
    }

    .my-week-header {
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;

        .week-label {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            line-height: 20px;
            font-weight: normal;
        }

        .day-label {
            font-weight: 700;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.87);
            line-height: 28px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            &.today {
                color: #ffffff;
                background: @border-color-select;
            }

            &.before {
                color: rgba(0, 0, 0, 0.38);
                background: transparent;
            }

            &.after {
                color: rgba(0, 0, 0, 0.87);
                background: transparent;
            }
        }

        .dots-wrapper {
            height: 8px;
        }
    }

    .loading {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 10;
        pointer-events: none;
    }

}
