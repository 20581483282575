.visitor-info-list-modal {
    :global(.ant-modal-body) {
        max-height: calc(80vh);
        overflow-y: scroll;
    }
    :global(.ant-modal-body) {
        padding-right: 16px;
    }
    :global(.ant-modal-footer) {
        padding-top: 16px;
    }
    :global(.ant-btn-link) {
        padding: 0;
    }
}
