@primary-color: #006EE1;

.wp-date-operator {
    display: flex;
    align-items: center;
    justify-content: center;

    .current-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 0 12px;
        line-height: 32px;
        background: transparent;
        border: 1px solid rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        cursor: pointer;
        user-select: none;

        @media (any-hover: hover) {
            &:hover {
                color: @primary-color;
                border-color: @primary-color;
            }
        }
    }

    .direction-btn-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        padding: 7px;
        border: 1px solid rgba(0, 0, 0, 0.13);
        border-radius: 4px;

        @media (any-hover: hover) {
            &:hover {
                border-color: @primary-color;
            }
        }

        .previous-btn, .next-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            cursor: pointer;

            @media (any-hover: hover) {
                &:hover {
                    color: @primary-color;
                }
            }

            &:active {
                opacity: 0.7;
            }
        }

        .divide-border-wrapper {
            margin: 0 7px;

            .border-line {
                width: 1px;
                height: 16px;
                background: rgba(0, 0, 0, 0.13);
            }
        }
    }
}
