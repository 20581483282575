@namespace: ~'wp';

.@{namespace}-date {
    &-selector {
        display: flex;

        .ant-btn {
            min-width: 64px;
            padding: 4px 7px;
            margin-right: 8px;
        }
    }

    &-operator {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        margin-right: 8px;

        .anticon {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .ant-divider {
            margin: 0 3px 0 2px;
        }

        &-left {
            padding: 0 4px 0 8px;

            &:active {
                opacity: .7;
            }
        }

        &-right {
            padding: 0 8px 0 4px;

            &:active {
                opacity: .7;
            }
        }
    }
}
