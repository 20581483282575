.user-schedule {
    position: relative;
    display: flex;
    height: 100%;
    padding: 5px;
    overflow: auto;

    &__day-wrapper {
        flex-shrink: 0;
        width: 387px;
        margin-right: 12px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 #00000003, 0 3px 6px 3px #00000003, 0 2px 6px 0 #00000008;
    }

    &__week-wrapper {
        flex: 1;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 #00000003, 0 3px 6px 3px #00000003, 0 2px 6px 0 #00000008;
    }

    .loading {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 12;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__background {
        background-color: #F7F9FB;
    }
}