.calendar-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    cursor: pointer;

    .pag-left,
    .pag-right {
        position: absolute;
        display: none;
    }

    .pag-left {
        left: -16px;
    }

    .pag-right {
        right: -16px;
    }

    .desk-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        padding: 8px 16px;
        .desk-name-wrapper {
            width: 100%;
            justify-content: flex-start;
            .desk-name {
                max-width: 100%;
                overflow: hidden;
                color: rgba(0, 0, 0, 0.87);
                font-size: 14px;
                line-height: 22px;
                white-space: nowrap;
                text-align: left;
                text-overflow: ellipsis;
            }
        }
    }

    .desk-header.ant-popover-open {
        background: rgba(0, 0, 0, 0.04);
    }
}

.rbc-time-header-content:nth-child(2) .calendar-header .PaginationBtn:first-child {
    display: flex;
}

.rbc-time-header-content:last-child .calendar-header .PaginationBtn:last-child {
    display: flex;
}

.meet-popover {
    min-width: 320px;
    min-height: 121px;
    padding: 0;

    .ant-popover-inner-content {
        padding: 24px;
    }

    .ant-popover-arrow {
        display: none !important;
    }
}

.header-desk-hasPeople {
    .green-icon {
        color: #20c161;
    }
    .gray-icon {
        color: rgba(104, 104, 104, 60%);
    }
    .orange-icon {
        color: orange;
    }
}
