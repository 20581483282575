@import '~@yeastar/pc-web-ui/src/style/variables.less';

.desk-booking-filter-header {
    display: flex;
    .ysd-segmented{
        margin-right: 24px;
        padding: 2px;
        .ysd-segmented-group {
            .ysd-segmented-item-label {
                min-height: 28px;
                padding: 0 8px;
                line-height: 28px;
            }
        }
    }
    .filter-header__right{
        flex: 1;
    }
    .operator-date {
        .ant-divider {
            padding-bottom: 2px;
        }
        .anticon {
            font-size: 16px;
            line-height: 16px;
            height: 16px;
        }
        .move-date {
            display: inline-block;
        }
    }
    .desk-star-checkbox {
        padding: 5px 0;
        white-space: nowrap;
    }
    .sel-occupancy {
        .ant-select-arrow {
            color: @gray-5;
        }
    }
}
.desk-booking-select__name {
    cursor: auto;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    .ant-select-item-option-content {
        display: flex;
        align-items: center;
        .anticon {
            margin-left: 4px;
            color: rgba(0, 0, 0, 0.38);
            cursor: pointer;
        }
    }
}
.wp-tooltip-input {
    .ant-select-arrow {
        z-index: 1;
        height: 100%;
        width: 32px;
        margin-top: 0;
        top: -1px;
        right: 3px;
        .anticon {
            width: 100%;
            height: 100%;
            line-height: 32px;
            color: rgba(0, 0, 0, 0.38);
            >svg {
                vertical-align: middle;
            }
        }
    }
}
.deskbooking-address__dropdown-container {
    .ant-select-tree {
        .ant-select-tree-treenode {
            padding: 0 16px;
            .ant-select-tree-node-content-wrapper {
                padding: 0 0 0 4px;
            }
            .ant-select-tree-switcher {
                text-align: left;
                width: 16px;
            }
        }
        .so-address-select__name {
            .ant-select-tree-switcher {
                width: auto;
            }
            .ant-select-tree-node-content-wrapper {
                padding: 0;
            }
        }
    }
}
