@import '~@yeastar/pc-web-ui/src/style/variables.less';
.custom-columns-button {
    margin-left: 12px;
    // min-width: 146px;
    padding: 6px 12px;
    span {
        vertical-align: middle;
    }
    .anticon {
        height: 16px;
    }
}
.setting-dropdown-content.columns-setting-content {
    width: 240px;
    background-color: #fff;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02),
        0 2px 6px 0 rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px 8px 12px 8px;
    max-height: 480px;
    overflow: auto;
    .setting-dropdown-content-header {
        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            padding-left: 8px;
        }
        p {
            font-size: 12px;
            line-height: 16px;
            color: @gray-7;
            padding-left: 8px;
        }
    }
    .small-title {
        line-height: 20px;
        color: @gray-7;
        margin-bottom: 4px;
        margin-top: 12px;
        padding-left: 8px;
    }
    .ant-list-item {
        padding: 8px;
        &:hover {
            background-color: @gray-1;
            border-radius: 8px;
        }
        .setting-list-item {
            display: flex;
            width: 100%;
            cursor: move;
            line-height: 20px;
            .drag-icon {
                margin-right: 8px;
                display: flex;
                margin-top: 3px;
                color: @gray-5;
            }
            .setting-list-item-checkbox {
                flex: 1;
                .ant-checkbox-wrapper {
                    display: flex;
                    .ant-checkbox + span {
                        flex: 1;
                    }
                }
            }
        }
    }
    .i-empty {
        text-align: center;
        line-height: 16px;
        padding: 10px 0;
        color: @gray-7;
        font-size: 12px;
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: @blue-2;
        border-color: @blue-2;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: @white;
    }
}
