.assign-to-user {
    margin-top: 24px;
    display: flex;
    .ant-select {
        flex: 1;
        overflow: hidden;
    }
    .assign-to-btn {
        display: block;
        min-width: 48px;
        margin-left: 12px;
        .anticon {
            display: inline-flex;
            align-items: center;
        }
    }
    .unassign-btn {
        width: 100%;
    }
    .select-user-none {
        background-color: #7ac8ff;
        border-color: #7ac8ff;
    }
}
