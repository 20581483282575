@b: web-client-feedback;
.@{b} {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    &__content {
        transform: translateY(-40px);
    }
}
