@import '~@yeastar/pc-web-ui/src/style/variables.less';

// 预约弹窗展开的样式
.meet-edit-popover--unfold {
    /** 覆盖掉antd popver组件自动生成的style属性值，避免弹窗整体偏移 */
    width: auto !important;
    left: 0 !important;
    top: 56px !important;
    padding: 0 !important;
    z-index: 1030 !important;
    .ant-popover-inner {
        border-radius: 0 !important;
    }
    .meet-edit-popover-content {
        height: calc(100% - 64px);
    }
    .ant-popover-inner-content {
        width: 100vw;
        // 56px是header的高度
        height: calc(100vh - 56px);
        overflow-x: hidden;
        .meeting-modal-container {
            display: flex;
            height: 100%;
        }
        .meeting-form-item-wrapper {
            padding-top: 24px;
        }
        .meeting-form-wrapper {
            width: 540px;
            height: 100%;
            border-right: 1px solid @separator-color-base;
            form {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
            .meeting-form-item-wrapper {
                // 41px 是头部 标题的高度
                height: calc(100% - 41px);
                margin-right: -24px;
                overflow: scroll;
            }
        }
        .meet-edit-popover-footer {
            padding: 16px 24px;
        }
    }
    .meet-edit-popover-title {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        padding-bottom: 16px;
        &::after {
            position: absolute;
            display: inline-block;
            content: '';
            height: 1px;
            background-color: @separator-color-base;
            width: calc(100% + 48px);
            left: -24px;
            bottom: 0;
        }
        .anticon {
            flex: none;
        }
        h5.ant-typography {
            // 补齐左侧箭头占据的宽度，让标题相对窗口居中
            text-indent: -24px;
            flex: 1;
            text-align: center;
        }
    }
}
.desk-detail-popover {
    padding: 0;
    display: flex;
    .ant-popover-content {
        .ant-popover-inner {
            border-radius: 8px;
        }
    }
    .ant-popover-inner-content {
        padding: 0;
    }
    .desk-detail-popover__left{
        width: 178px;
        padding: 8px;
        border-right: 1px solid rgba(0,0,0,0.05) ;

        .left-content{
            min-height: 200px;
            max-height: 600px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .MeetingDetailMore-left-item{
            //height: 32px;
            font-size: 12px;
            text-align: center;
            //line-height: 32px;
            border-radius: 4px;
            padding: 5px 12px;
            cursor: pointer;
            word-wrap: break-word;
        }
        .MeetingDetailMore-left-item:hover{
            background: rgba(0,0,0,0.04);
        }
        .MeetingDetailMore-left-item.active{
            color: #0054ba;
            background: #e6f6ff;
            // border: 1px solid #e6f6ff;
        }

    }
    .desk-detail-popover__right{
        flex: 1;
        width: 480px;
        .calendar-detail-disable-wrapper {
            width: 480px;
        }
    }
    // &:has(.desk-detail-popover__aggregate) {
    //     width: 540px;
    // }
    .desk-detail-popover__aggregate {
        width: 362px;
    }
}
