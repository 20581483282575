@import '~@yeastar/pc-web-ui/src/style/variables.less';

.details-drawer {
    color: @gray-base;
    :global(.ant-typography h2) {
        font-size: @font-size-hg;
        line-height: 28px;
        margin-bottom: 4px;
    }
    :global(div.ant-typography) {
        margin-bottom: 0;
    }
    :global(.ant-drawer-header) {
        padding: 16px;
    }
    :global(.ant-drawer-title) {
        line-height: 24px;
    }
    :global(.ant-drawer-body) {
        padding: 0;
    }
    :global(.ant-drawer-close) {
        padding: 16px;
    }
    :global(.ant-spin-nested-loading > div > .ant-spin) {
        top: 0;
        transform: none;
        height: 100vh;
    }
    .info-wrapper {
        height: 100%;
        padding: 16px;
        overflow: auto;
    }
    .info {
        .row {
            line-height: 20px;
            margin-top: 20px;
            .label {
                font-size: @font-size-base;
                color: @gray-7;
                display: inline-block;
            }
            .value {
                margin-top: 4px;
                margin-bottom: 0 !important;
            }
        }
        margin-bottom: 60px;
    }
    .button-group {
        width: 100%;
        margin: 0 -16px;
        padding: 12px 24px;
        position: absolute;
        bottom: 0;
        z-index: 1;
        text-align: right;
        border-top: 1px solid @gray-2;
    }
    
}
