.desk-booking {
    width: 100%;
    height: calc(100% + 16px);
    display: flex;
    flex-direction: column;
    .desk-booking-calendar {
        flex: 1;
        margin-top: 24px;
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        overflow-y: clip;
        //主要是复写样式
        .ys-calendar-meetings {
            width: calc(100% + 48px);
            height: 100%;

            .ant-spin-nested-loading {
                width: 100%;
                height: 100%;

                .ant-spin-container {
                    width: 100%;
                    height: 100%;
                }
            }

            // 格数太少时，占满屏幕
            .rbc-time-content {
                .rbc-time-column {
                    min-width: 100%;
                }

                .rbc-multiple-vertical-timezone {
                    min-width: 100%;
                }
            }

            .new-calendar .rbc-time-view-resources .rbc-time-header .rbc-time-header-gutter {
                height: auto;
            }
        }
        .calendar-no-overflow {
            .rbc-time-view-resources {
                overflow-y: hidden;
            }
        }
        .ant-result.ant-result-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
        // 为了让时间处于格子中间
        .new-calendar {
            position: relative;
            .rbc-time-view-resources {
                .rbc-time-header {
                    .rbc-time-header-gutter {
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -1px;
                            right: -16px;
                            width: 16px;
                            background-color: #f2f2f2;
                            height: 1px;
                        }
                    }
                }
                .rbc-time-content {
                    .rbc-time-gutter {
                        z-index: 98;
                        .rbc-timeslot-group {
                            .rbc-time-slot {
                                padding-left: 3px;
                                background: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
