.booked-record-popover {
    .ant-popover-inner {
        border-radius: 8px;
    }
    .ant-popover-inner-content {
        max-height: 422px;
        overflow-y: auto;
        padding: 24px;
    }
}
