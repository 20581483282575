.new-feature-modal {
    .ant-modal-body {
        padding: 0;
    }

    .nfm-img-wrapper {
        height: 292px;
        background: #e6f6ff url('./new_feature@2x.png') center no-repeat;
        background-size: 520px 212px;
        border-radius: 10px 10px 0 0;
    }

    .nfm-content {
        padding: 24px;

        > p {
            margin-bottom: 0;
        }

        > h2 {
            font-size: 24px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 8px;
        }
    }
}
