.header-popover-content {
    max-width: 280px;
    .header-desk-name {
        font-size: 18px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87);
        // margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 26px;
        .fill-start-icon {
            font-size: 20px;
            cursor: pointer;
            color: orange;
        }
        .star-icon {
            font-size: 20px;
            color: #a5a5a5;
            cursor: pointer;
        }
        .header-desk-show-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 8px;
        }
    }

    .header-desk-row {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 12px;
        overflow: hidden;
        display: flex;
        align-items: center;
        .anticon {
            margin: 0;
        }
        .header-desk-col {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 8px;
            font-weight: 400;
            font-size: 12px;
            color: rgba(0,0,0,0.60);
            line-height: 16px;
        }
    }
    .header-desk-remark {
        align-items: flex-start;
        .header-desk-remark-col {
            overflow: unset;
            white-space: unset;
            text-overflow: unset;
            word-break: break-all;
        }
    }
    .header-desk-building {
        margin-top: 24px;
    }
    .header-desk-sensor {
        margin-top: 8px;
    }

    .header-desk-hasPeople {
        .green-icon {
            color: #20c161;
        }
        .gray-icon {
            color: rgba(104, 104, 104, 60%);
        }
        .orange-icon {
            color: orange;
        }
    }
    .assign-desk-booked-block {
        margin-top: 24px;
        .assign-booked-name {
            margin-left: 8px;
            font-weight: bold;
        }
    }
    .no-reservation-auth {
        margin-top: -12px;
        margin-bottom: 24px;
        color: rgba(0, 0, 0, 0.6);
    }
}
