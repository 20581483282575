.EventContent{
    .event-msg{
        margin-top: 2px;
        font-size: 12px;
        line-height: normal;
    }
    .event-subject{
        margin-top: 0px !important;
        font-weight: bold;
    }
    .moreContent {
        overflow: hidden;
        word-break: break-all;
    }
}
