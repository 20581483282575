.print-wrapper {
    height: 0;
    overflow: hidden;
}
.print-container {
    .print-content {
        // width: 560px;
    }
    &.landscape {
        width: 544px;
        img {
            width: 544px;
            height: 324px; // 横屏竖屏计算的方式不一样，设置边距后无法完全按照比例走，这里直接设置宽高
        }
    }
    &.portrait {
        width: 340px;
    }
    img {
        max-width: 100%;
    }
}
