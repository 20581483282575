@import '~@yeastar/pc-web-ui/src/style/variables.less';

.calendar-detail-content-wrapper {
    padding: 24px 24px 0 24px;
    overflow: hidden;
    &-subject {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 18px;
        word-break: normal;
    }
    div.ant-typography {
        margin-bottom: 16px;
        font-size: @font-size-xs;
        &.first {
            margin-bottom: 24px;
            font-size: @font-size-base;
        }
        &:last-of-type {
            padding-bottom: 8px;
        }
    }
}

.desk-edit-popover-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    .ant-btn {
        min-width: 0;
        margin: 8px 0 8px 12px;
    }
}
