@import '~@yeastar/pc-web-ui/src/style/variables.less';

// 会议日程列表
.day-view-calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__list {
        position: relative;
        flex: 1 1 auto;
        margin-top: 16px;
    }

    .loading {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .ant-spin-nested-loading {
            width: auto;
            height: auto;
        }
    }

    &__list-scroll-view {
        :global {
            .sticky-container {
                padding-bottom: 20px;
                background: #fff;
                &:first-child {
                    .sticky-row-item {
                        color: @blue-5;
                    }
                }
                &-active {
                    .sticky-row-item {
                        color: @blue-5;
                    }
                }
            }

            .sticky-row {
                &-item {
                    position: sticky !important;
                    top: 0;
                    z-index: 9;
                    width: 100%;
                    height: 20px;
                    padding-left: @padding-md;
                    font-weight: 600;
                    font-size: @font-size-base;
                    line-height: 20px;
                    background: #fff;
                    transform: translateZ(0);
                }
            }
        }
    }
}
