@namespace: ~'wp';

.@{namespace}-capacity-input {
    padding: 12px 16px 8px 16px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.01), 0 3px 6px 3px rgba(0,0,0,0.01), 0 2px 6px 0 rgba(0,0,0,0.03);
    border-radius: 4px;

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        input {
            min-width: 90px;
        }

        // 设置 input 等比缩放
        .ant-form-item {
            flex: 1 1 0;
        }
    }

    &__line {
        width: 12px;
        height: 1px;
        margin: 15px 8px 0;
        background: rgba(0,0,0,0.87);
    }

    &__bottom {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
    }

    // 清空 ant form 表单 margin-bottom
    .ant-form-item {
        margin-bottom: 0;
    }

    // 清空背景 shadow
    &.clear-input-box-shadow {
        box-shadow: none;
    }

    .ant-form-item-label {
        label {
            font-size: 12px;
        }
    }
}
