.cascader-select-show-map {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    padding: 4px 8px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    transition: border 0.3s, box-shadow 0.3s;
    width: 150px;
    display: flex;
    justify-content: space-between;
}
