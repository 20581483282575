.mapWrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .empty {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .so-plan-lock-block {
        height: 100%;

        .ant-spin-container {
            height: 100%;
        }
    }
    .ant-result {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .icon {
        position: absolute;
        z-index: 9;
        user-select: none;
        background-color: #fe8c00;
        color: #fff;
        border-radius: 999999px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 3px rgba(0, 0, 0, 0.1),
            0 2px 6px 0 rgba(0, 0, 0, 0.3);
        margin: 0 auto;
        border: 2px solid #fff;
        pointer-events: none;
    }
    .label {
        position: absolute;
        z-index: 9;
        user-select: none;
        background-color: #fff;
        border-radius: 999999px;
        padding: 4px 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 3px rgba(0, 0, 0, 0.1),
            0 2px 6px 0 rgba(0, 0, 0, 0.3);
        margin: 0 auto;
        height: 30px;
        white-space: nowrap;
        pointer-events: none;
        &::after {
            position: absolute;
            content: '';
            border-top: 8px solid #fff;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            left: 50%;
            top: 100%;
            transform: translateX(-50%);
        }
    }
    .mydesk {
        position: absolute;
        z-index: 9;
        user-select: none;
        background-color: #006ee1;
        border-radius: 999999px;
        color: #ffffff;
        padding: 4px 8px;
        border: 1px solid #eee;
        filter: drop-shadow(2px 2px 2px #fff);
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 3px rgba(0, 0, 0, 0.1),
        //     0 2px 6px 0 rgba(0, 0, 0, 0.3);
        margin: 0 auto;
        height: 30px;
        line-height: 19px;
        white-space: nowrap;
        pointer-events: none;
        &::after {
            position: absolute;
            content: '';
            border-top: 8px solid #006ee1;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            left: 50%;
            top: 100%;
            transform: translateX(-50%);
        }
    }
    .iconStyles {
        position: fixed;
        left: 24px;
        bottom: 16px;
        background-color: #fff;
        padding: 8px 12px;
        border-radius: 4px;
        display: flex;
        z-index: 99;
        user-select: none;
        .itemType {
            font-size: 12px;
            line-height: 16px;
            .cube {
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #20C161;
                vertical-align: text-top;
                margin-right: 3px;
            }
        }
        .itemType + .itemType {
            margin-left: 20px;
        }
    }
    .newbie-virtual-free-mark {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        background-color: transparent;
        pointer-events: none;
        margin: 0 12px;
        .newbie-mark-animation {
            position: absolute;
            top: 100%;
            left: calc(100% - 12px);
            transform: translate(-50%, -50%);
            pointer-events: none;
        }
    }
}
.map-menu-arrow {
    display: inline-block;
    height: 100%;
    .anticon {
        vertical-align: middle;
        color: rgba(0, 0, 0, 0.38);
        cursor: pointer;
    }
}
