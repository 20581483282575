.meet-room-content {
    width: 100%;
    height: 100%;
    padding-bottom: 16px;
    .ant-spin-nested-loading,
    .ant-spin-container {
        height: 100%;
    }

    .meet-room-title {
        display: flex;
        margin-bottom: 12px;

        &-list .anticon {
            color: rgba(0, 0, 0, 0.38);
        }

        .meet-room-star-checkbox {
            padding: 5px 0;
            white-space: nowrap;
        }

        .ysd-segmented {
            margin-right: 24px;
            padding: 2px;
            .ysd-segmented-group {
                .ysd-segmented-item-label {
                    min-height: 28px;
                    padding: 0 8px;
                    line-height: 28px;
                }
            }
        }

        .wp-filter-bar {
            flex: 1;
        }
    }

    .meet-room-calendar {
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
    }
    @media screen and (max-width: 772px) {
        .meet-room-calendar {
            position: relative;
            width: 100%;
            height: calc(100% - 64px);
        }
    }
    .meet-room-foot {
        position: absolute;
        right: -18px;
        bottom: 0px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);

        .refresh-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 16px;
        }
        .ant-pagination-total-text {
            line-height: 32px;
        }
    }
    .ant-result.ant-result-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .ant-select-selection-item-content {
        max-width: 165px;
    }

    .ant-cascader-picker-arrow {
        color: rgba(0, 0, 0, 0.87);
    }

    .cascader-select-show {
        cursor: pointer;
        .cascader-select-text {
            display: inline-block;
            max-width: 165px;
            margin-right: 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
        .anticon {
            vertical-align: middle;
        }
    }
}
.meet-room-select__name {
    cursor: auto;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    .ant-select-item-option-content {
        display: flex;
        align-items: center;
        .anticon {
            margin-left: 4px;
            color: rgba(0, 0, 0, 0.38);
            cursor: pointer;
        }
    }
}
.meet-room-tree-node__name {
    cursor: auto;
    &:hover {
        background-color: #fff;
    }
    .ant-select-tree-checkbox {
        display: none;
    }
    .ant-select-tree-node-content-wrapper {
        padding-left: 0 !important;
        cursor: auto !important;
    }
    .ant-select-tree-title {
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
    }
}
.wp-tooltip-input {
    .ant-select-arrow {
        z-index: 1;
        height: 100%;
        width: 32px;
        margin-top: 0;
        top: -1px;
        right: 3px;
        .anticon {
            width: 100%;
            height: 100%;
            line-height: 32px;
            color: rgba(0, 0, 0, 0.38);
            > svg {
                vertical-align: middle;
            }
        }
    }
}
.meetroom-address__dropdown-container {
    .ant-select-tree {
        .ant-select-tree-treenode {
            padding: 0 16px 0 16px;
            position: relative;
            .ant-select-tree-node-content-wrapper {
                padding: 0 0 0 4px;
            }
            .ant-select-tree-switcher {
                text-align: left;
                width: 16px;
            }
        }
        .so-address-select__name {
            .ant-select-tree-switcher {
                width: auto;
            }
            .ant-select-tree-node-content-wrapper {
                padding: 0;
            }
        }
    }
}
.wp-facilty--dropdown {
    .ant-select-tree {
        .ant-select-tree-treenode {
            padding: 0 16px;
            .ant-select-tree-node-content-wrapper {
                padding: 0 0 0 4px;
            }
            .ant-select-tree-switcher-noop {
                width: auto;
            }
        }
    }
}
