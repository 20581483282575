.my-desk-dropdown {
    cursor: pointer;
    .my-desk-block {
        height: 32px;
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 4px;
        padding: 4px 8px;
        white-space: nowrap;

        .ant-avatar {
            margin-right: 4px;
        }
        .my-desk-label {
            display: inline-flex;
            align-items: center;
            .my-desk-text {
                vertical-align: bottom;
                margin-right: 4px;
            }
            .anticon {
                vertical-align: middle;
            }
        }
    }
}
.my-desk-empty-container {
    width: 320px;
    height: 320px;
    //background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.my-desk-overlay-container {
    width: 320px;
    background-color: #fff;
    .my-desk-title {
        font-size: 18px;
        font-weight: bold;
        cursor: auto;
        margin-bottom: 12px;
        padding: 20px 20px 0 20px;
    }
    .menu-record-item {
        padding: 0;
        .my-desk-menu-record {
            max-height: 320px;
            padding: 0 20px 20px 20px;
            overflow: auto;
            .my-desk-content {
                background-color: #f7f7f7;
                border-radius: 4px;
                padding: 12px 16px;
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
                .my-desk-name {
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 4px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .my-desk-building {
                    color: #959494;
                    margin-bottom: 4px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .my-desk-extra {
                    color: #959494;
                    white-space: initial;
                    .ant-tag {
                        border: unset;
                        padding: 0 4px;
                    }
                }
            }
        }
    }
    .my-desk-footer {
        padding: 0 20px 20px 20px;
        .ant-btn {
            font-size: 14px;
            width: 100%;
            height: 32px;
            padding: 8px 0;
        }
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-item-active {
        background-color: unset;
    }
}
