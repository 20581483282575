@namespace: ~'wp';

.@{namespace}-filter-bar {
    display: flex;

    &-left {
        display: flex;
        flex: 1;
    }

    &-right {
        display: flex;
    }

    &-item {
        margin-left: 24px;

        &:first-child {
            margin-left: 0;
        }
    }

    &-more-btn {
        width: 76px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        user-select: none;
        cursor: pointer;

        &:active {
            opacity: .6;
        }

        > .anticon {
            color: rgba(0, 0, 0, 0.38);
            margin-right: 4px;
        }

        &.filtered {
            width: 96px;
        }

        .filtered-tag {
            display: inline-block;
            width: 16px;
            height: 16px;
            color: #fff;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            border-radius: 8px;
            background-color: #006EE1;
            margin-right: 4px;
            vertical-align: 1px;
        }
    }

    &-dropdown {
        padding: 24px;
        width: 280px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04), 0 6px 12px 12px rgba(0, 0, 0, 0.04), 0 6px 10px 0 rgba(0, 0, 0, 0.08);

        > h3 {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }

        > .@{namespace}-filter-bar-item {
            margin: 16px 0 0 0;
        }

        .anticon {
            color: rgba(0, 0, 0, 0.38);
        }
    }
}
