.date-dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__dot {
        border-radius: 50%;

        &.blue {
            background: #006EE1;
        }

        &.green {
            background: #20C161;
        }

        &.yellow {
            background: #FFA629;
        }

        &:not(:first-child) {
            margin-left: 2px;
        }
    }
}
