.record-content {
    width: 360px;
    .header {
        //margin-bottom: 24px;
        .header-name {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 4px;
        }
        .header-building {
            margin-bottom: 4px;
        }

        .green-icon {
            color: #20c161;
        }
        .gray-icon {
            color: rgba(104, 104, 104, 60%);
        }
        .orange-icon {
            color: orange;
        }
        .header-popover-content {
            max-width: unset;
        }
    }
    .record {
        margin-top: 24px;
        .record-item {
            display: flex;
            padding: 8px 16px;
            background: rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            margin-bottom: 2px;
            cursor: pointer;
            .record-time {
                // width: 120px;
                padding-top: 2px;
                margin-right: 16px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .record-user {
            }
        }
    }
    .footer {
        .first-time {
            margin-top: 24px;
            width: 100%;
        }
        .base-time {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .ant-btn {
                width: calc(50% - 8px);
                margin-top: 16px;
                padding: 0;
            }
        }
        .suggest_button_animation {
            position: absolute;
            top: 50%;
            left: calc(100% - 56px);
            transform: translate(-50%, -50%);
            pointer-events: none;
        }
    }
    .no-reservation-auth {
        display: inline-block;
        margin-top: 12px;
        color: rgba(0, 0, 0, 0.6);
    }
    .assign-desk-booked-block {
        margin-top: 24px;
        .assign-booked-name {
            margin-left: 8px;
            font-weight: bold;
            margin-bottom: -24px;
        }
    }
}



